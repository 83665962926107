.drawing-layout {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	background: url("../../../assets/backgroundGrid.svg");
	background-size: cover;
	background-color: #f2f2f2;
	background-repeat: no-repeat;
	position: relative;
}

@media (max-width: 800px) {
	.drawing-layout {
		flex-direction: column-reverse;
	}
}
