.header {
	width: 100%;
	min-height: 80px;
	border-bottom: 1px solid lightgrey;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.prime-header {
	min-height: 62px;
}

.secondary-header {
	min-height: 42px;
}

.center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.left-header-container {
	height: 100%;
	display: flex;
	align-self: flex-start;
	flex: 1 1 0%;
}

.center-header-container {
	width: 100px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
	max-width: 100%;
	align-self: end;
	/* border: 1px solid red; */
}

.right-header-container {
	height: 100%;
	display: flex;
	align-items: center;
	gap: 20px;
	justify-content: flex-end;
	flex: 1 1 0%;
}

.clear-button-wrapper {
	width: 120px;
	height: 100%;
	border-right: 0.2px solid #d3d3d3;
}

.clear-canvas-button {
	outline: none;
	border: none;
	background: none;
	cursor: pointer;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 20px;
}

.set-background-container {
	padding: 0 10px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
}

.save-button-wrapper {
	padding: 0 40px 0 10px;
	height: 100%;
}

.avatar-group-wrapper {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	/* padding: 0 30px 0 30px; */
}

.save-canvas-button {
	outline: none;
	border: none;
	background: none;
	cursor: pointer;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #376af5;
}

.whiteboard-name-container {
	height: 100%;
	/* border-right: 0.2px solid #9c9c9c; */
}
