/* <div className="share-component-wrapper">
			<div className="share-text">test</div>
			<button className="copyIcon">hi {ToolIcons.CopyIcon()}</button> */

.share-component-wrapper {
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	padding: 15px 20px;
	border-radius: 4px;
	background-color: #fff;
	border: 0.5px solid rgb(196, 196, 196);
}

.copyIcon {
	cursor: pointer;
}

.copy-text {
	background-color: white;
	border: none;
	text-decoration: none;
	width: 90%;
	overflow: hidden;
	font-size: 16px;
	overflow-x: scroll;
}
