.drawing-tools-container {
	height: 100%;
	/* width: 170px; */
	/* min-width: 170px; */
	display: flex;
	align-items: center;
	justify-content: center;
}
@media (max-width: 800px) {
	.drawing-tools-container {
		height: 10%;
		min-height: 55px;
	}
}

.tool-box-wrapper {
	padding: 6px;
	gap: 10px;
	background: #ffffff;
	box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	margin-left: 20px;
}

@media (max-width: 800px) {
	.tool-box-wrapper {
		padding: 8px 20px;
		flex-wrap: wrap;
		flex-direction: row;
		width: 100%;
		margin-left: 0px;
	}
}

.tool-button {
	width: 36px;
	height: 36px;
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tool-button:hover {
	background: #e2edf5;
	color: #376af5;
}
