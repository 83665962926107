.drawing-area-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.drawing-canvas-wrapper {
	/* width: 95%;
	height: 90%;
	background: #ffffff;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	overflow: hidden;
	position: relative; */
	background: #ffffff;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	overflow: hidden;
	width: 85vw;
	/* 90% of viewport vidth */
	height: 47.8125vw;
	/* ratio = 9/16 * 90 = 50.625 */
	max-height: 85vh;
	max-width: 151.11vh;
	/* 16/9 * 90 = 160 */
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.drawing-canvas {
	width: 100%;
	height: 100%;
}

.canvas-board {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.minimap {
	position: fixed !important;
	bottom: 10px !important;
	right: 10px !important;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media screen and (max-width: 800px) {
	.minimap {
		visibility: hidden;
	}
}
